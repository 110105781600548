import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';

import { useAppDispatch } from '../../../../store';
import { editAsset, uploadAssetImage } from '../../../../store/assets/asyncThunks/slice';
import CryptoAssetForm from '../../CryptoAssetForm/CryptoAssetForm';
import { DataType, FormValues } from '../types';

import styles from './EditAssetModal.module.scss';

const modalStyles = {
  content: {
    padding: 0,
    width: 600,
  },
  header: {
    padding: '16px 24px',
    borderBottom: '1px solid #F0F0F0',
    marginBottom: 0,
  },
};

interface EditAssetModalProps {
  isModalOpen: boolean;
  editAssetData: DataType | null;
  handleCancel: () => void;
  onGetAssets: () => void;
}

export default function EditAssetModal({ isModalOpen, editAssetData, handleCancel, onGetAssets }: EditAssetModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [statusValue, setStatusValue] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (editAssetData) {
      form.setFieldsValue({
        ...editAssetData,
        isActive: editAssetData.isActive,
      });
      setStatusValue(editAssetData.isActive || false);
    }
  }, [form, editAssetData]);

  const onSubmit = async (values: FormValues) => {
    const { id } = editAssetData || {};
    setLoading(true);
    const requestBody = {
      name: values.name,
      multiplierMin: values.multiplierMin,
      multiplierMax: values.multiplierMax,
      wagerMin: values.wagerMin,
      wagerMax: values.wagerMax,
      takeProfitLimit: values.takeProfitLimit,
      stopLossLimit: values.stopLossLimit,
      flatFeePercent: values.flatFeePercent,
      pnlFeePercent: values.pnlFeePercent,
      fundingFeePercent: values.fundingFeePercent,
      fundingFeeTimeframe: values.fundingFeeTimeframe,
      finageId: values.finageId,
      coingeckoId: values.coingeckoId,
      rtp: values.rtp,
      volatility: values.volatility,
      isActive: statusValue,
    };
    const isChanged = Object.keys(requestBody).some(
      (key) => requestBody[key as keyof FormValues] !== editAssetData?.[key as keyof typeof editAssetData],
    );
    let assetId = editAssetData?.id;
    let shouldFetchAssets = false;
    if (isChanged) {
      const res = await dispatch(editAsset({ assetId: id as number, body: requestBody }));
      assetId = res?.payload?.id;
      if (assetId) {
        shouldFetchAssets = true;
      }
    }
    if (assetId && selectedImage) {
      await dispatch(uploadAssetImage({ file: selectedImage, assetId }));
      setSelectedImage(null);
      shouldFetchAssets = true;
    }
    if (shouldFetchAssets) {
      await onGetAssets();
      handleCancel();
    }
    setLoading(false);
  };

  const handleChangeStatus = () => {
    setStatusValue((prev) => !prev);
  };

  const handleChangeImage = (value: File) => {
    setSelectedImage(value);
  };

  return (
    <Modal title='Edit asset' open={isModalOpen} onCancel={handleCancel} footer={null} styles={modalStyles}>
      <Form onFinish={onSubmit} requiredMark={false} form={form}>
        <CryptoAssetForm
          form={form}
          status={statusValue}
          selectedImage={selectedImage}
          isEditModal
          assetIcon={editAssetData?.icon}
          onToggle={handleChangeStatus}
          onChangeImage={handleChangeImage}
        />
        <div className={styles.footer}>
          <Button key='cancel' onClick={handleCancel} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button key='ok' type='primary' htmlType='submit' className={styles.okButton} loading={loading}>
            Edit asset
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
