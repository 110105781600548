import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import setupAxiosInterceptors from '../api/axiosInstance';
import { useAppDispatch } from '../store';
import { logoutAction, setTokensAction } from '../store/user/slice';

const ApiService = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  const handleSetTokens = useCallback(
    (accessToken: string, refreshToken: string) => {
      dispatch(setTokensAction({ accessToken: accessToken, refreshToken: refreshToken }));
    },
    [dispatch],
  );

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    setupAxiosInterceptors(handleLogout, handleSetTokens, handleNavigate);
  }, [handleLogout]);

  return null;
};

export default ApiService;
