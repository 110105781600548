import { useEffect } from 'react';
import { Button, Form, Modal } from 'antd';

import InputField from '../../../../components/InputField/InputField';
import { AdminRoles, AdminStatuses } from '../../../../constants/constants';
import { useAppDispatch } from '../../../../store';
import { createAdmin } from '../../../../store/admins/asyncThunks/slice';

import RoleStatusBlock from './RoleStatusBlock/RoleStatusBlock';

import styles from './AddAdminModal.module.scss';

const modalStyles = {
  content: {
    padding: 0,
  },
  header: {
    padding: '16px 24px',
    borderBottom: '1px solid #F0F0F0',
    marginBottom: 0,
  },
};

interface AddAdminModalProps {
  isOpenModal: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  onGetAdmins: () => void;
}

interface ValuesData {
  fullName: string;
  email: string;
  role: string;
  status: string;
}

export default function AddAdminModal({ isOpenModal, handleOk, handleCancel, onGetAdmins }: AddAdminModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const onSubmit = async (values: ValuesData) => {
    handleOk();
    const requestBody = {
      fullName: values.fullName,
      email: values.email,
      role: values.role,
      status: values.status,
    };
    await dispatch(createAdmin(requestBody));
    form.resetFields();
    onGetAdmins();
  };

  useEffect(() => {
    form.setFieldsValue({
      role: AdminRoles.ADMIN,
      status: AdminStatuses.ACTIVE,
    });
  }, [form]);

  return (
    <Modal title='Add admin' open={isOpenModal} onCancel={handleCancel} footer={null} styles={modalStyles}>
      <Form onFinish={onSubmit} className={styles.form} requiredMark={false} form={form}>
        <div className={styles.main}>
          <InputField
            label='Full name'
            name='fullName'
            rules={[
              { required: true, message: 'Please enter your full name' },
              { min: 3, message: 'Full name must be at least 3 characters long' },
              {
                pattern: /^[A-Za-z\s]+$/,
                message: 'Full name must contain only letters and spaces',
              },
            ]}
            placeholder='Enter full name'
          />
          <InputField
            label='Email'
            name='email'
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
            placeholder='Enter user’s email'
          />
          <RoleStatusBlock />
        </div>
        <div className={styles.footer}>
          <Button key='cancel' onClick={handleCancel} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button key='ok' type='primary' htmlType='submit' className={styles.okButton}>
            Send invite
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
