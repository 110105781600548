import { useEffect, useState } from 'react';
import { GetProps, Input } from 'antd';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAppDispatch } from '../../store';
import { getAdmins } from '../../store/admins/asyncThunks/slice';

import AdminManagementTable from './AdminManagementTable/AdminManagementTable';
import Toolbar from './Toolbar/Toolbar';

type SearchProps = GetProps<typeof Input.Search>;

export default function AdminManagementPage() {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<string>('');

  const { admins, paginationConfig, sort, loading } = useTypedSelector((state) => state.admins);

  const handleGetAdmins = async () => {
    const params = {
      filter: searchValue,
      page: paginationConfig.number - 1,
      size: paginationConfig.size,
      sort: sort,
    };
    await dispatch(getAdmins(params));
  };

  useEffect(() => {
    handleGetAdmins();
  }, [sort, paginationConfig.number, paginationConfig.size, searchValue]);

  const handleSearch: SearchProps['onSearch'] = (value, _e) => {
    setSearchValue(value);
  };

  const handleClear = () => {
    setSearchValue('');
  };

  return (
    <div>
      <Toolbar onSearch={handleSearch} onClear={handleClear} onGetAdmins={handleGetAdmins} />
      <AdminManagementTable
        admins={admins}
        loading={loading}
        paginationConfig={paginationConfig}
        onGetAdmins={handleGetAdmins}
      />
    </div>
  );
}
