import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, notification } from 'antd';

import { useAppDispatch } from '../../../../store';
import { createAsset, uploadAssetImage } from '../../../../store/assets/asyncThunks/slice';
import CryptoAssetForm from '../../CryptoAssetForm/CryptoAssetForm';
import { FormValues } from '../../CryptoAssetsTable/types';

import styles from './AddAssetModal.module.scss';

const modalStyles = {
  content: {
    padding: 0,
    width: 600,
  },
  header: {
    padding: '16px 24px',
    borderBottom: '1px solid #F0F0F0',
    marginBottom: 0,
  },
};

interface AddAssetModalProps {
  isOpenModal: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  onGetAssets: () => void;
}

export default function AddAssetModal({ isOpenModal, handleOk, handleCancel, onGetAssets }: AddAssetModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);

  const handleChangeStatus = () => {
    setStatus((prev) => !prev);
  };

  const onSubmit = async (values: FormValues) => {
    if (!selectedImage) {
      return;
    }
    setLoading(true);
    const requestBody = {
      name: values.name,
      multiplierMin: values.multiplierMin,
      multiplierMax: values.multiplierMax,
      wagerMin: values.wagerMin,
      wagerMax: values.wagerMax,
      takeProfitLimit: values.takeProfitLimit,
      stopLossLimit: values.stopLossLimit,
      flatFeePercent: values.flatFeePercent,
      pnlFeePercent: values.pnlFeePercent,
      fundingFeePercent: values.fundingFeePercent,
      fundingFeeTimeframe: values.fundingFeeTimeframe,
      finageId: values.finageId,
      coingeckoId: values.coingeckoId,
      rtp: values.rtp,
      volatility: values.volatility,
    };
    const res = await dispatch(createAsset(requestBody));
    const assetId = res?.payload?.id;
    if (assetId) {
      await dispatch(
        uploadAssetImage({
          file: selectedImage,
          assetId: assetId,
        }),
      );
      await onGetAssets();
      form.resetFields();
      setSelectedImage(null);
      notification.success({
        message: `Created`,
        description: 'New asset has been successfully added.',
        key: 'create-asset-success',
        duration: 3,
      });
      handleOk();
    }
    setLoading(false);
  };

  const handleChangeImage = (value: File) => {
    setSelectedImage(value);
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [isOpenModal]);

  return (
    <Modal title='Add asset' open={isOpenModal} onCancel={handleCancel} footer={null} styles={modalStyles}>
      <Form onFinish={onSubmit} requiredMark={false} form={form}>
        <CryptoAssetForm
          form={form}
          status={status}
          selectedImage={selectedImage}
          onToggle={handleChangeStatus}
          onChangeImage={handleChangeImage}
        />
        <div className={styles.footer}>
          <Button key='cancel' onClick={handleCancel} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button key='ok' type='primary' htmlType='submit' className={styles.okButton} loading={loading}>
            Add asset
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
