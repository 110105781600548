import { createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';

import { AdminRolesText } from '../../constants/constants';

import { createAdmin, getAdmins } from './asyncThunks/slice';
import { AdminsState } from './types';

const initialState: AdminsState = {
  admins: [],
  loading: false,
  error: null,
  paginationConfig: {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    number: 1,
  },
  sort: '',
};

export const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    changePaginationAction: (state, action) => {
      state.paginationConfig.number = action.payload.current;
      state.paginationConfig.size = action.payload.pageSize;
    },
    changeSortAction: (state, action) => {
      state.sort = action.payload.sort;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdmins.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdmins.fulfilled, (state, { payload }) => {
      const { data } = payload;
      state.admins = data.content;
      state.paginationConfig.size = data.page.size;
      state.paginationConfig.totalElements = data.page.totalElements;
      state.paginationConfig.totalPages = data.page.totalPages;
      state.paginationConfig.number = data.page.number + 1;
      state.loading = false;
    });
    builder.addCase(getAdmins.rejected, (state, action) => {
      state.admins = [];
      state.loading = false;
      state.error = action.payload || 'Unknown error';
    });

    builder.addCase(createAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAdmin.fulfilled, (state, { payload }) => {
      const roleKey = payload.role as keyof typeof AdminRolesText;
      state.loading = false;
      notification.success({
        message: `${AdminRolesText[roleKey]} created`,
        description: `The ${AdminRolesText[roleKey]}  has been successfully added.`,
        key: 'create-admin-success',
        duration: 3,
      });
    });
    builder.addCase(createAdmin.rejected, (state, action) => {
      const errorMessage = action?.payload?.message;
      state.loading = false;
      notification.error({
        message: 'Error',
        description: typeof errorMessage === 'string' ? errorMessage : 'An unexpected error occurred',
        key: 'create-admin-error',
        duration: 3,
      });
    });
  },
});

export const { changePaginationAction, changeSortAction } = adminsSlice.actions;

export default adminsSlice.reducer;
