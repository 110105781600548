import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { requester } from '../../../api/axiosInstance';
import { BASE_URL } from '../../../constants/constants';
import { GetUserResponse, IEditUserRequest, ILoginData, ISendPasswordData } from '../types';

export const login = createAsyncThunk('user/login', async (loginData: ILoginData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/login`, loginData);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data || 'Login failed');
  }
});

export const revokeRefreshToken = createAsyncThunk(
  'user/revokeRefreshToken',
  async (refreshToken: string, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/token/revoke`, { refreshToken });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Error');
    }
  },
);

export const getUserData = createAsyncThunk<GetUserResponse, void, { rejectValue: string }>(
  'user/getUserData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await requester.get('/api/account');
      return { data: response.data };
    } catch (error) {
      return rejectWithValue('Failed to fetch data');
    }
  },
);

export const editUser = createAsyncThunk('/user/editUser', async (editAdminRequestBody: IEditUserRequest) => {
  return requester.put('/api/account', editAdminRequestBody);
});

export const setUserPassword = createAsyncThunk(
  'user/setUserPassword',
  async (requestData: ISendPasswordData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/reset-password/complete`, requestData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Error');
    }
  },
);

export const resetPassword = createAsyncThunk<any, string, { rejectValue: any }>(
  'user/resetPassword',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/reset-password`, { email });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { message: 'Error resetting password' });
    }
  },
);
