import React, { useCallback, useEffect } from 'react';
import { FormInstance } from 'antd';

import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import DoubleInputField from '../../../components/DoubleInputField/DoubleInputField';
import InputField from '../../../components/InputField/InputField';
import { useDebounceCallback } from '../../../hooks/useDebounceCallback';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useAppDispatch } from '../../../store';
import { getAssetsMarketSymbolsCoinGecko, getAssetsMarketSymbolsFinage } from '../../../store/assets/asyncThunks/slice';

import ImageBlock from './ImageBlock/ImageBlock';
import StatusBlock from './StatusBlock/StatusBlock';

import styles from './CryptoAssetForm.module.scss';

interface CryptoAssetFormProps {
  form: FormInstance;
  status: boolean;
  isEditModal?: boolean;
  assetIcon?: string;
  selectedImage: File | null;
  onToggle: () => void;
  onChangeImage: (image: File) => void;
}

const PROVIDER_FINAGE = 'FINAGE';
const PROVIDER_COIN_GECKO = 'COIN_GECKO';
const MULTIPLIER_MAX_VALUE = 1000;

export default function CryptoAssetForm({
  form,
  status,
  isEditModal,
  assetIcon,
  selectedImage,
  onToggle,
  onChangeImage,
}: CryptoAssetFormProps) {
  const dispatch = useAppDispatch();

  const { marketSymbolsCoinGecko, marketSymbolsFinage, loadingMarketSymbols } = useTypedSelector(
    (state) => state.assets,
  );

  const mapMarketSymbols = (symbols?: { symbol: string; name: string }[]) =>
    symbols?.map(({ symbol, name }) => ({ value: symbol, label: name })) || [];

  const marketSymbolsCoinGeckoOptions = mapMarketSymbols(marketSymbolsCoinGecko);
  const marketSymbolsFinageOptions = mapMarketSymbols(marketSymbolsFinage);

  const handleGetAssetsMarketSymbolsFinage = async (searchValue: string = '') => {
    const params = {
      provider: PROVIDER_FINAGE,
      filter: searchValue,
      size: 100,
    };
    dispatch(getAssetsMarketSymbolsFinage(params));
  };

  const handleGetAssetsMarketSymbolsCoinGecko = async (searchValue: string = '') => {
    const params = {
      provider: PROVIDER_COIN_GECKO,
      filter: searchValue,
      size: 100,
    };
    dispatch(getAssetsMarketSymbolsCoinGecko(params));
  };

  const handleSearchCoinFinage = useCallback(
    useDebounceCallback(async (search: string) => {
      handleGetAssetsMarketSymbolsFinage(search);
    }, 300),
    [],
  );

  const handleSearchCoinGecko = useCallback(
    useDebounceCallback(async (search: string) => {
      handleGetAssetsMarketSymbolsCoinGecko(search);
    }, 300),
    [],
  );

  useEffect(() => {
    handleGetAssetsMarketSymbolsFinage();
    handleGetAssetsMarketSymbolsCoinGecko();
  }, []);

  const requiredLabel = (text: string) => (
    <div>
      {text} <span style={{ color: '#ff4d4f' }}>*</span>
    </div>
  );

  return (
    <div className={styles.main}>
      <div className={styles.item}>
        <CustomSelect
          label={requiredLabel('Asset symbol (Finage)')}
          name='finageId'
          placeholder='Enter asset symbol'
          options={marketSymbolsFinageOptions}
          loading={loadingMarketSymbols}
          handleSearch={handleSearchCoinFinage}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value || getFieldValue('coingeckoId')) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter asset symbol'));
              },
            }),
          ]}
          showSearch
        />

        <CustomSelect
          label={requiredLabel('Asset symbol (Coingecko)')}
          name='coingeckoId'
          placeholder='Enter asset symbol'
          options={marketSymbolsCoinGeckoOptions}
          loading={loadingMarketSymbols}
          handleSearch={handleSearchCoinGecko}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value || getFieldValue('finageId')) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter asset symbol'));
              },
            }),
          ]}
          showSearch
        />
      </div>
      <div className={styles.item}>
        <InputField
          label={requiredLabel('Display name')}
          name='name'
          rules={[
            { required: true, message: 'Please enter display name' },
            { min: 3, message: 'Display  name must be at least 3 characters long' },
          ]}
          placeholder='Enter display name'
        />
      </div>
      <div className={styles.item}>
        <InputField
          label={requiredLabel('Volatility')}
          name='volatility'
          rules={[
            { required: true, message: 'Please enter volatility' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Volatility  must not be negative'));
                }
                if (value > 200) {
                  return Promise.reject(new Error('Volatility cannot exceed 200'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='Enter volatility'
          type='number'
        />
        <InputField
          label={requiredLabel('RTP')}
          name='rtp'
          rules={[
            { required: true, message: 'Please enter RTP' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('RTP must not be negative'));
                }
                if (value > 100) {
                  return Promise.reject(new Error('RTP cannot exceed 100'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='0.00%'
          type='number'
        />
      </div>
      <div className={styles.item}>
        <DoubleInputField
          label={requiredLabel('Wager (min/max)')}
          name1='wagerMin'
          name2='wagerMax'
          rules1={[
            { required: true, message: 'Please enter wager min' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Wager min must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          rules2={[
            { required: true, message: 'Please enter wager max' },
            {
              validator: (_, value) => {
                const min = form.getFieldValue('wagerMin');
                if (value < 0) {
                  return Promise.reject(new Error('Wager max must not be negative'));
                }
                if (value <= min) {
                  return Promise.reject(new Error('Wager max must be greater than wager min'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder1='Min'
          placeholder2='Max'
        />
        <DoubleInputField
          label='Multiplier (min/max)'
          name1='multiplierMin'
          name2='multiplierMax'
          rules1={[
            { required: true, message: 'Please enter multiplier min' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Multiplier min must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          rules2={[
            { required: true, message: 'Please enter multiplier max' },
            {
              validator: (_, value) => {
                const minValue = form.getFieldValue('multiplierMin');
                if (value < 0) {
                  return Promise.reject(new Error('Multiplier max must not be negative'));
                }
                if (value <= minValue) {
                  return Promise.reject(new Error('Multiplier max must be greater than multiplier min'));
                }
                if (value > MULTIPLIER_MAX_VALUE) {
                  return Promise.reject(new Error('Multiplier max must not exceed 1000'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder1='Min'
          placeholder2='Max'
        />
      </div>
      <div className={styles.item}>
        <InputField
          label={requiredLabel('Limit (take profit)')}
          name='takeProfitLimit'
          rules={[
            { required: true, message: 'Please enter limit (take profit)' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Take profit must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='Enter limit'
          type='number'
        />
        <InputField
          label={requiredLabel('Limit (stop loss)')}
          name='stopLossLimit'
          rules={[
            { required: true, message: 'Please enter limit (stop loss)' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Stop loss must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='Enter limit'
          type='number'
        />
      </div>
      <div className={styles.item}>
        <InputField
          label={requiredLabel('Flat Fee')}
          name='flatFeePercent'
          rules={[
            { required: true, message: 'Please enter Flat fee' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Flat fee must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='0.00%'
          type='number'
        />
        <InputField
          label={requiredLabel('PNL Fee')}
          name='pnlFeePercent'
          rules={[
            { required: true, message: 'Please enter PNL fee' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('PNL fee must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='0.00%'
          type='number'
        />
      </div>
      <div className={styles.item}>
        <InputField
          label={requiredLabel('Funding Fee Percent')}
          name='fundingFeePercent'
          rules={[
            { required: true, message: 'Please enter Funding Fee Percent' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Funding Fee Percent must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='Enter %'
          type='number'
        />
        <InputField
          label={requiredLabel('Funding Fee Timeframe')}
          name='fundingFeeTimeframe'
          rules={[
            { required: true, message: 'Please enter Funding Fee Timeframe' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Funding Fee Timeframe must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='Enter N of hours'
          type='number'
        />
      </div>
      <div className={styles.item}>
        <ImageBlock selectedImage={selectedImage} assetIcon={assetIcon} onChangeImage={onChangeImage} />
        {isEditModal && <StatusBlock status={status} onToggle={onToggle} />}
      </div>
    </div>
  );
}
