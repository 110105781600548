import React, { useEffect, useState } from 'react';
import type { TablePaginationConfig } from 'antd';
import { Switch, TableProps } from 'antd';
import { Table } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';

import { getSortOrder } from '../../../helpers/helpers';
import { useAppDispatch } from '../../../store';
import { editAsset } from '../../../store/assets/asyncThunks/slice';
import { changePaginationAction, changeSortAction } from '../../../store/assets/slice';
import { IAsset } from '../../../types/entities';

import Actions from './Actions/Actions';
import DeleteAssetModal from './DeleteAssetModal/DeleteAssetModal';
import EditAssetModal from './EditAssetModal/EditAssetModal';
import { DataType } from './types';

import styles from './CryptoAssetsTable.module.scss';

interface CryptoAssetsTableProps {
  assets: IAsset[];
  loading: boolean;
  paginationConfig: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
  };
  onGetAssets: () => void;
}

export default function CryptoAssetsTable({ assets, loading, paginationConfig, onGetAssets }: CryptoAssetsTableProps) {
  const dispatch = useAppDispatch();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);

  const [data, setData] = useState<DataType[]>([]);
  const [editAssetData, setEditAssetData] = useState<DataType | null>(null);
  const [deleteAssetData, setDeleteAssetData] = useState<number | null>(null);

  useEffect(() => {
    setData(assets as DataType[]);
  }, [assets]);

  const handleOpenEditModal = (dataEditAsset: DataType) => {
    setEditAssetData(dataEditAsset);
    setIsOpenEditModal(true);
  };

  const handleTableChange = (
    paginationData: TablePaginationConfig,
    _?: Record<string, FilterValue | null>,
    sorter?: SorterResult<DataType> | SorterResult<DataType>[],
  ) => {
    let sortOrder = '';
    if (Array.isArray(sorter)) {
      sortOrder = sorter.length > 0 && sorter[0].order ? `${sorter[0].columnKey},${getSortOrder(sorter[0].order)}` : '';
    } else if (sorter?.order) {
      sortOrder = `${sorter.columnKey},${getSortOrder(sorter.order)}`;
    }
    dispatch(
      changePaginationAction({
        current: paginationData.current ?? 1,
        pageSize: paginationData.pageSize ?? 10,
      }),
    );
    dispatch(
      changeSortAction({
        sort: sortOrder,
      }),
    );
  };

  const handlePaginationChange = (page: number, pageSize?: number) => {
    handleTableChange({ current: page, pageSize });
  };

  const handleOpenDeleteModal = (isRecordToggle: boolean, assetId: number) => {
    if (!isRecordToggle) {
      setIsOpenDeleteModal(true);
      setDeleteAssetData(assetId);
    }
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const handleToggle = (id: string | number) => {
    const toggledData = data.map((item) => (item.id === id ? { ...item, isActive: !item.isActive } : item));
    setData(toggledData);

    const selectedAsset = toggledData.find((item) => item.id === id);

    const requestBody = {
      name: selectedAsset?.name,
      multiplierMin: selectedAsset?.multiplierMin,
      multiplierMax: selectedAsset?.multiplierMax,
      wagerMin: selectedAsset?.wagerMin,
      wagerMax: selectedAsset?.wagerMax,
      takeProfitLimit: selectedAsset?.takeProfitLimit,
      stopLossLimit: selectedAsset?.stopLossLimit,
      flatFeePercent: selectedAsset?.flatFeePercent,
      pnlFeePercent: selectedAsset?.pnlFeePercent,
      fundingFeePercent: selectedAsset?.fundingFeePercent,
      fundingFeeTimeframe: selectedAsset?.fundingFeeTimeframe,
      finageId: selectedAsset?.finageId,
      coingeckoId: selectedAsset?.coingeckoId,
      rtp: selectedAsset?.rtp,
      volatility: selectedAsset?.volatility,
      isActive: selectedAsset?.isActive,
    };

    if (selectedAsset) {
      const updatePayload = {
        assetId: id as number,
        body: requestBody,
      };
      dispatch(editAsset(updatePayload));
    }
  };

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Img',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon) => <img src={icon} alt='Asset' style={{ width: 25, height: 25 }} />,
      width: 70,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 100,
    },
    {
      title: 'Asset name',
      dataIndex: 'symbol',
      key: 'symbol',
      sorter: true,
      width: 130,
    },
    {
      title: 'Volatility',
      dataIndex: 'volatility',
      key: 'volatility',
      sorter: true,
      width: 120,
    },
    {
      title: 'RTP',
      dataIndex: 'rtp',
      key: 'rtp',
      sorter: true,
      render: (text) => {
        return <span>{text}%</span>;
      },
      width: 80,
    },
    {
      title: 'Wager (min/max)',
      dataIndex: 'wagerMin',
      key: 'wagerMin',
      render: (_, record) => {
        return (
          <div className={styles.toggleContainer}>
            <div>
              <span style={{ color: '#00000073' }}>min </span>
              {record?.wagerMin}
            </div>
            /
            <div>
              <span style={{ color: '#00000073' }}>max </span>
              {record?.wagerMax}
            </div>
          </div>
        );
      },
      width: 170,
    },
    {
      title: 'Multiplier (min/max)',
      dataIndex: 'multiplierMIn',
      key: 'multiplierMin',
      render: (_, record) => {
        return (
          <div className={styles.toggleContainer}>
            <div>
              <span style={{ color: '#00000073' }}>min </span>
              {record.multiplierMin}
            </div>
            /
            <div>
              <span style={{ color: '#00000073' }}>max </span>
              {record.multiplierMax}
            </div>
          </div>
        );
      },
      width: 170,
    },
    {
      title: 'Limit (take profit)',
      dataIndex: 'takeProfitLimit',
      key: 'takeProfitLimit',
      sorter: true,
      width: 170,
    },
    {
      title: 'Limit (stop loss)',
      dataIndex: 'stopLossLimit',
      key: 'stopLossLimit',
      sorter: true,
      width: 160,
    },
    {
      title: 'Flat Fee',
      dataIndex: 'flatFeePercent',
      key: 'flatFeePercent',
      sorter: true,
      render: (text) => {
        return <span>{text}%</span>;
      },
      width: 100,
    },
    {
      title: 'PNL Fee',
      dataIndex: 'pnlFeePercent',
      key: 'pnlFeePercent',
      sorter: true,
      render: (text) => {
        return <span>{text}%</span>;
      },
      width: 100,
    },
    {
      title: 'Funding Fee',
      dataIndex: 'fundingFeePercent',
      key: 'fundingFeePercent',
      sorter: true,
      render: (text) => {
        return <span>{text}%</span>;
      },
      width: 130,
    },
    {
      title: '',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (value, record) => (
        <div className={styles.toggleContainer}>
          <span className={styles.toggleLabel}>Off</span>
          <Switch value={value} onChange={() => handleToggle(record.id)} />
          <span>On</span>
        </div>
      ),
      width: 130,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Actions
          record={record}
          onOpenEditModal={handleOpenEditModal}
          onOpenDeleteModal={() => handleOpenDeleteModal(record.isActive, record.id)}
        />
      ),
      width: 80,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <Table<DataType>
        columns={columns}
        dataSource={data}
        rowKey='id'
        pagination={{
          current: paginationConfig.number,
          pageSize: paginationConfig.size,
          total: paginationConfig.totalElements,
          showSizeChanger: true,
          showQuickJumper: true,
          onChange: handlePaginationChange,
        }}
        onChange={handleTableChange}
        scroll={{ y: window.innerHeight - 225, x: 1600 }}
        loading={loading}
      />
      <DeleteAssetModal
        isModalOpen={isOpenDeleteModal}
        assetId={deleteAssetData}
        handleCancel={handleCloseDeleteModal}
        onGetAssets={onGetAssets}
      />
      <EditAssetModal
        isModalOpen={isOpenEditModal}
        editAssetData={editAssetData}
        handleCancel={handleCloseEditModal}
        onGetAssets={onGetAssets}
      />
    </div>
  );
}
