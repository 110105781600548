import { Form, Radio } from 'antd';

import { AdminStatuses } from '../../../../../../constants/constants';

import styles from './Status.module.scss';

export default function Status() {
  return (
    <div>
      <div className={styles.title}>Status</div>
      <Form.Item name='status'>
        <Radio.Group>
          <Radio value={AdminStatuses.ACTIVE} className={styles.customRadio}>
            Active
          </Radio>
          <Radio value={AdminStatuses.BLOCKED} className={styles.customRadio}>
            Blocked
          </Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
}
