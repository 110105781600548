import React, { FC, useEffect, useState } from 'react';
import { TablePaginationConfig, TableProps, Tag } from 'antd';
import { Checkbox, Table } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FilterValue, SorterResult } from 'antd/es/table/interface';

import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import { AdminRoles, AdminRolesText, AdminStatuses } from '../../../constants/constants';
import { formatDate, getSortOrder } from '../../../helpers/helpers';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useAppDispatch } from '../../../store';
import { deleteAdmin } from '../../../store/admins/asyncThunks/slice';
import { changePaginationAction, changeSortAction } from '../../../store/admins/slice';
import { selectUserId } from '../../../store/user/selectors';
import { IAdmin } from '../../../types/entities';

import Actions from './Actions/Actions';
import EditAdminModal from './EditAdminModal/EditAdminModal';
import { DataType } from './types';

import styles from './AdminManagementTable.module.scss';

interface AdminManagementTableProps {
  admins: IAdmin[];
  loading: boolean;
  paginationConfig: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
  };
  onGetAdmins: () => void;
}

const AdminManagementTable: FC<AdminManagementTableProps> = ({ admins, loading, paginationConfig, onGetAdmins }) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<DataType[]>([]);

  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [deleteAdminData, setDeleteAdminData] = useState<any>(null);
  const [editAdminData, setEditAdminData] = useState<any>(null);

  const userId = useTypedSelector(selectUserId);

  useEffect(() => {
    const newData = admins?.map((admin) => {
      return {
        key: admin.id.toString(),
        id: admin.id,
        email: admin.email,
        fullName: admin.fullName,
        role: admin.role,
        status: admin.status,
        lastActiveAt: admin.lastActiveAt,
        selected: false,
      };
    });
    setData(newData);
  }, [admins]);

  const handleCheckboxChange = (checked: boolean, key: string) => {
    const updatedData = data.map((item) => (item.key === key ? { ...item, selected: checked } : item));
    setData(updatedData);
  };

  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    const updatedData = data.map((item) => ({ ...item, selected: checked }));
    setData(updatedData);
    setAllSelected(checked);
  };

  const handleTableChange = (
    paginationData: TablePaginationConfig,
    _?: Record<string, FilterValue | null>,
    sorter?: SorterResult<DataType> | SorterResult<DataType>[],
  ) => {
    let sortOrder = '';
    if (Array.isArray(sorter)) {
      sortOrder = sorter.length > 0 && sorter[0].order ? `${sorter[0].columnKey},${getSortOrder(sorter[0].order)}` : '';
    } else if (sorter?.order) {
      sortOrder = `${sorter.columnKey},${getSortOrder(sorter.order)}`;
    }
    dispatch(
      changePaginationAction({
        current: paginationData.current ?? 1,
        pageSize: paginationData.pageSize ?? 10,
      }),
    );
    dispatch(
      changeSortAction({
        sort: sortOrder,
      }),
    );
  };

  const handlePaginationChange = (page: number, pageSize?: number) => {
    handleTableChange({ current: page, pageSize });
  };

  const handleOpenDeleteModal = (value: DataType) => {
    setDeleteAdminData(value);
    setIsOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const handleDelete = async () => {
    await dispatch(deleteAdmin(deleteAdminData?.id));
    onGetAdmins();
    setIsOpenDeleteModal(false);
  };

  const handleOpenEditModal = (dataEditAdmin: DataType) => {
    setEditAdminData(dataEditAdmin);
    setIsOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      title: <Checkbox checked={allSelected} onChange={handleSelectAllChange} />,
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: (_, record: DataType) => (
        <Checkbox checked={record.selected} onChange={(e) => handleCheckboxChange(e.target.checked, record.key)} />
      ),
      width: '4%',
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      width: '20%',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      sorter: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text: keyof typeof AdminRolesText) => (
        <Tag color={text === AdminRoles.ADMIN ? 'purple' : 'blue'} style={{ textAlign: 'center' }}>
          {AdminRolesText[text]}
        </Tag>
      ),
      width: '12%',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Tag color={text === AdminStatuses.ACTIVE ? 'green' : 'red'}>{text}</Tag>,
      width: '12%',
      sorter: true,
    },
    {
      title: 'Last Active',
      dataIndex: 'lastActiveAt',
      key: 'lastActiveAt',
      render: (text) => <p>{formatDate(text)}</p>,
      width: '15%',
      sorter: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Actions
          record={record}
          isDisabled={userId === record.id.toString()}
          onOpenEditModal={handleOpenEditModal}
          onOpenDeleteModal={handleOpenDeleteModal}
          onGetAdmins={onGetAdmins}
        />
      ),
      width: '10%',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <Table<DataType>
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey='key'
        pagination={{
          current: paginationConfig.number,
          pageSize: paginationConfig.size,
          total: paginationConfig.totalElements,
          showSizeChanger: true,
          showQuickJumper: true,
          onChange: handlePaginationChange,
        }}
        onChange={handleTableChange}
        scroll={{ y: window.innerHeight - 205 }}
      />
      <ConfirmModal
        isModalOpen={isOpenDeleteModal}
        title='Are you sure delete this admin?'
        text={
          <>
            User with email <span>{deleteAdminData?.email}</span> will no longer have access to CoinPoker Admin panel.
          </>
        }
        okButtonText='Delete'
        handleCancel={handleCloseDeleteModal}
        handleOk={handleDelete}
      />
      <EditAdminModal
        isOpenModal={isOpenEditModal}
        editAdminData={editAdminData}
        onCloseEditModal={handleCloseEditModal}
        onGetAdmins={onGetAdmins}
      />
    </div>
  );
};

export default AdminManagementTable;
