import { Link } from 'react-router-dom';
import { Button, Form } from 'antd';

import InputField from '../../../components/InputField/InputField';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useAppDispatch } from '../../../store';
import { login } from '../../../store/user/asyncThunks/slice';

import styles from './LoginForm.module.scss';

type ValuesType = {
  email: string;
  password: string;
};

export default function LoginForm() {
  const dispatch = useAppDispatch();

  const { error, loading } = useTypedSelector((state) => state.user);

  const onSubmit = async (values: ValuesType) => {
    const loginData = {
      email: values.email,
      password: values.password,
    };
    dispatch(login(loginData));
  };

  const renderError = () => {
    return error?.message && <div className={styles.error}>{error.message}</div>;
  };

  return (
    <Form onFinish={onSubmit} className={styles.form} requiredMark={false}>
      <InputField
        label='Email'
        name='email'
        rules={[
          { required: true, message: 'Please enter your email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
        placeholder='Enter your email'
      />
      <InputField
        label='Password'
        name='password'
        rules={[{ required: true, message: 'Please enter your password' }]}
        placeholder='input password'
        type='password'
      />
      {renderError()}
      <div className={styles.formCheckboxWrapper}>
        <Link to={'forgot-password'} className={styles.formCheckboxWrapper_link}>
          Forgot password?
        </Link>
      </div>
      <div className={styles.formItem}>
        <Button type='primary' htmlType='submit' className={styles.formItem_button} loading={loading}>
          Login
        </Button>
      </div>
    </Form>
  );
}
