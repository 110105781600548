import { useEffect } from 'react';
import { Form, Radio } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

import { AdminRoles, AdminRolesText } from '../../../../../../constants/constants';

import styles from './Role.module.scss';

export default function Role() {
  const form = useFormInstance();
  const value = form.getFieldValue('role');

  useEffect(() => {
    if (value === undefined) {
      form.setFieldsValue({ role: AdminRoles.ADMIN });
    }
  }, [value, form]);

  return (
    <div>
      <div className={styles.title}>Role</div>
      <Form.Item name='role'>
        <Radio.Group value={value}>
          <Radio value={AdminRoles.ADMIN} className={styles.customRadio}>
            {AdminRolesText.ADMIN}
          </Radio>
          <Radio value={AdminRoles.SUPER_ADMIN} className={styles.customRadio}>
            {AdminRolesText.SUPER_ADMIN}
          </Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
}
