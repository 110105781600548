import { createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';

import { removeTokensFromLocalStorage, setTokensToLocalStorage } from '../../helpers/helpers';

import { getUserData, login, resetPassword } from './asyncThunks/slice';
import { UserState } from './types';

const initialState: UserState = {
  isAuth: Boolean(localStorage.getItem('accessToken')),
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),
  loading: false,
  error: null,
  userData: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutAction: (state) => {
      state.isAuth = false;
      state.accessToken = null;
      state.refreshToken = null;
      removeTokensFromLocalStorage();
    },
    setTokensAction: (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      state.isAuth = true;
      setTokensToLocalStorage(accessToken, refreshToken);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      setTokensToLocalStorage(accessToken, refreshToken);
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.loading = false;
      state.isAuth = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
    builder.addCase(getUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.userData = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getUserData.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      const errorMessage = action?.payload?.message;
      state.loading = false;
      notification.error({
        message: 'Error',
        description: typeof errorMessage === 'string' ? errorMessage : 'An unexpected error occurred',
        key: 'user-reset-password-error',
        duration: 3,
      });
    });
  },
});

export const { logoutAction, setTokensAction } = userSlice.actions;

export default userSlice.reducer;
