import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { ReactComponent as AssetIcon } from '../../assets/icons/asset.svg';
import { ReactComponent as AvatarIcon } from '../../assets/icons/avatar.svg';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as SidebarArrowIcon } from '../../assets/icons/sidebar-arrow.svg';
import { ReactComponent as TicketIcon } from '../../assets/icons/ticket.svg';
import { AdminRoles } from '../../constants/constants';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAppDispatch } from '../../store';
import { toggleSidebarAction } from '../../store/ui/slice';
import { revokeRefreshToken } from '../../store/user/asyncThunks/slice';
import { selectUserRole } from '../../store/user/selectors';
import { logoutAction } from '../../store/user/slice';
import ConfirmModal from '../ConfirmModal/ConfirmModal';

import styles from './Sidebar.module.scss';

const getNavItems = (role: string | null) => {
  const baseItems = [
    { to: '/crypto-assets', icon: <AssetIcon />, label: 'Crypto Assets' },
    { to: '/bets-management', icon: <TicketIcon />, label: 'Bets Management' },
    { to: '/profile-details', icon: <SettingOutlined />, label: 'Profile Details' },
  ];
  if (role === AdminRoles.SUPER_ADMIN) {
    return [{ to: '/admin-management', icon: <UserOutlined />, label: 'Admin Management' }, ...baseItems];
  }
  return baseItems;
};

export default function Sidebar() {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { isShortSidebar } = useTypedSelector((state) => state.ui);
  const userRole = useTypedSelector(selectUserRole);

  const handleToggleNavbar = () => dispatch(toggleSidebarAction());

  const handleShowModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    const refreshToken = localStorage.getItem('refreshToken') || '';
    dispatch(revokeRefreshToken(refreshToken));
    dispatch(logoutAction());
  };

  const navItems = getNavItems(userRole);

  return (
    <>
      <div className={cn(styles.sidebar, { [styles.sidebar_short]: isShortSidebar })}>
        <div className={styles.sidebar_top}>
          <LogoIcon />
        </div>
        <div className={styles.sidebar_list}>
          {navItems.map(({ to, icon, label }) => (
            <NavLink
              key={to}
              to={to}
              className={({ isActive }) =>
                cn(styles.sidebar_list_item, { [styles.sidebar_list_item_active]: isActive })
              }
            >
              {icon}
              {!isShortSidebar && <span>{label}</span>}
            </NavLink>
          ))}
        </div>
        <div className={styles.sidebar_profile}>
          <div className={styles.sidebar_profile_left}>
            {!isShortSidebar && (
              <>
                <AvatarIcon />
                <div>super-admin</div>
              </>
            )}
          </div>
          <div className={styles.sidebar_profile_right} onClick={handleShowModal}>
            <LogoutOutlined style={{ color: '#FF4D4F' }} />
          </div>
        </div>
        <div className={styles.sidebar_collapse} onClick={handleToggleNavbar}>
          <SidebarArrowIcon style={{ transform: `rotate(${isShortSidebar ? 180 : 0}deg)` }} />
        </div>
      </div>
      <ConfirmModal
        isModalOpen={isModalOpen}
        title='Are you sure want logout?'
        text="You're about to log out. Make sure your work is saved before proceeding."
        okButtonText='Logout'
        handleCancel={handleCancel}
        handleOk={handleLogout}
      />
    </>
  );
}
