import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import CustomSearch from '../../../components/CustomSearch/CustomSearch';

import AddAssetModal from './AddAssetModal/AddAssetModal';

import styles from './Toolbar.module.scss';

interface ToolbarProps {
  onSearch: (value: string) => void;
  onClear: () => void;
  onGetAssets: () => void;
}

export default function Toolbar({ onSearch, onClear, onGetAssets }: ToolbarProps) {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleOk = () => {
    setIsOpenModal(false);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles.toolbar_title}>Crypto Assets</div>
        <div className={styles.toolbar_right}>
          <CustomSearch onSearch={onSearch} onClear={onClear} />
          <div>
            <Button type='primary' className={styles.toolbar_button} onClick={handleOpenModal}>
              <PlusOutlined /> Add Asset
            </Button>
          </div>
        </div>
      </div>
      <AddAssetModal
        isOpenModal={isOpenModal}
        handleOk={handleOk}
        handleCancel={handleCloseModal}
        onGetAssets={onGetAssets}
      />
    </>
  );
}
