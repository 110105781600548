import { Form, Radio } from 'antd';

import { AdminRoles, AdminRolesText } from '../../../../../../constants/constants';

import styles from './Role.module.scss';

export default function Role() {
  return (
    <div>
      <div className={styles.title}>Role</div>
      <Form.Item name='role'>
        <Radio.Group>
          <Radio value={AdminRoles.ADMIN} className={styles.customRadio}>
            {AdminRolesText.ADMIN}
          </Radio>
          <Radio value={AdminRoles.SUPER_ADMIN} className={styles.customRadio}>
            {AdminRolesText.SUPER_ADMIN}
          </Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
}
