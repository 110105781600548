import { createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';

import {
  createAsset,
  editAsset,
  getAssets,
  getAssetsMarketSymbolsCoinGecko,
  getAssetsMarketSymbolsFinage,
} from './asyncThunks/slice';
import { AssetsState } from './types';

const initialState: AssetsState = {
  assets: [],
  loading: false,
  error: null,
  marketSymbolsCoinGecko: [],
  marketSymbolsFinage: [],
  loadingMarketSymbols: false,
  paginationConfig: {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    number: 1,
  },
  sort: '',
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    changePaginationAction: (state, action) => {
      state.paginationConfig.number = action.payload.current;
      state.paginationConfig.size = action.payload.pageSize;
    },
    changeSortAction: (state, action) => {
      state.sort = action.payload.sort;
    },
  },
  extraReducers: (builder) => {
    // Get assets
    builder.addCase(getAssets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssets.fulfilled, (state, { payload }) => {
      const { data } = payload;
      state.assets = data.content;
      state.paginationConfig.size = data.page.size;
      state.paginationConfig.totalElements = data.page.totalElements;
      state.paginationConfig.totalPages = data.page.totalPages;
      state.paginationConfig.number = data.page.number + 1;
      state.loading = false;
    });
    builder.addCase(getAssets.rejected, (state, action) => {
      const errorMessage = action?.payload?.message;
      state.assets = [];
      state.loading = false;
      notification.error({
        message: 'Error',
        description: typeof errorMessage === 'string' ? errorMessage : 'An unexpected error occurred',
        key: 'fetch-assets-error',
        duration: 3,
      });
    });

    // Get CoinGecko symbols
    builder.addCase(getAssetsMarketSymbolsCoinGecko.pending, (state) => {
      state.loadingMarketSymbols = true;
    });
    builder.addCase(getAssetsMarketSymbolsCoinGecko.fulfilled, (state, action) => {
      state.marketSymbolsCoinGecko = action.payload.data;
      state.loadingMarketSymbols = false;
    });
    builder.addCase(getAssetsMarketSymbolsCoinGecko.rejected, (state, action) => {
      state.marketSymbolsCoinGecko = [];
      state.loadingMarketSymbols = false;
      state.error = action.payload || 'Unknown error';
    });

    // Get Finage symbols
    builder.addCase(getAssetsMarketSymbolsFinage.pending, (state) => {
      state.loadingMarketSymbols = true;
    });
    builder.addCase(getAssetsMarketSymbolsFinage.fulfilled, (state, action) => {
      state.marketSymbolsFinage = action.payload.data;
      state.loadingMarketSymbols = false;
    });
    builder.addCase(getAssetsMarketSymbolsFinage.rejected, (state, action) => {
      state.marketSymbolsFinage = [];
      state.loadingMarketSymbols = false;
      state.error = action.payload || 'Unknown error';
    });

    // Create asset
    builder.addCase(createAsset.rejected, (_, action) => {
      const errorMessage = action?.payload?.message;
      notification.error({
        message: 'Error',
        description: typeof errorMessage === 'string' ? errorMessage : 'An unexpected error occurred',
        key: 'create-asset-error',
        duration: 3,
      });
    });

    // Edit asset
    builder.addCase(editAsset.fulfilled, () => {
      notification.success({
        message: `Edit`,
        description: `Asset has been successfully edited.`,
        key: 'edit-asset-success',
        duration: 3,
      });
    });
    builder.addCase(editAsset.rejected, (_, action) => {
      const errorMessage = action?.payload?.message;
      notification.error({
        message: 'Error',
        description: typeof errorMessage === 'string' ? errorMessage : 'An unexpected error occurred',
        key: 'edit-asset-error',
        duration: 3,
      });
    });
  },
});

export const { changePaginationAction, changeSortAction } = assetsSlice.actions;

export default assetsSlice.reducer;
