import React, { ChangeEvent, useRef } from 'react';
import { Button, message, notification } from 'antd';
import { Form } from 'antd';

import styles from './ImageBlock.module.scss';

interface ImageBlockProps {
  selectedImage?: File | null;
  assetIcon?: string;
  onChangeImage?: (image: File) => void;
}

export default function ImageBlock({ selectedImage, assetIcon, onChangeImage }: ImageBlockProps) {
  const myInput = useRef<HTMLInputElement | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const isValidType = ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);

      if (!isValidType) {
        notification.error({
          message: `Error`,
          description: 'Please upload a valid image file (jpeg, jpg, png)',
          key: 'upload-asset-icon-error',
          duration: 5,
        });
        return;
      }

      onChangeImage?.(file);
    }
  };

  const handleClick = () => {
    myInput.current?.click();
  };

  const getAssetIcon = () => {
    if (selectedImage) {
      return <img src={URL.createObjectURL(selectedImage)} alt='image' />;
    }
    if (assetIcon) {
      return <img src={assetIcon} alt='image' />;
    }
    return <div className={styles.imageBlock_circle} />;
  };

  return (
    <Form.Item
      className={styles.item}
      name={'image'}
      rules={[{ required: !assetIcon && true, message: 'Please upload image' }]}
    >
      <div className={styles.imageBlock}>
        <div className={styles.imageBlock_avatar}>{getAssetIcon()}</div>
        <div>
          <Button onClick={handleClick}>Upload image</Button>
          <div className={styles.imageBlock_text}>*jpeg, jpg, png formats</div>
        </div>
        <input type='file' ref={myInput} className={styles.imageBlock_input} onChange={handleChange} />
      </div>
    </Form.Item>
  );
}
