import React from 'react';
import { DeleteOutlined, EditOutlined, LockOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

import { AdminStatuses } from '../../../../constants/constants';
import { useAppDispatch } from '../../../../store';
import { editAdmin } from '../../../../store/admins/asyncThunks/slice';
import { DataType } from '../types';

interface ActionsProps {
  record: DataType;
  isDisabled?: boolean;
  onOpenEditModal: (record: DataType) => void;
  onOpenDeleteModal: (record: DataType) => void;
  onGetAdmins: () => void;
}

const iconStyle = { fontSize: '16px', cursor: 'pointer' as const };
const disabledStyle = { color: '#d9d9d9', cursor: 'not-allowed' as const };

export default function Actions({ record, isDisabled, onOpenEditModal, onOpenDeleteModal, onGetAdmins }: ActionsProps) {
  const dispatch = useAppDispatch();
  const handleEdit = async () => {
    const editAdminRequestData = {
      userId: record?.id,
      body: {
        fullName: record?.fullName || '',
        email: record?.email || '',
        role: record?.role || '',
        status: record?.status === AdminStatuses.ACTIVE ? AdminStatuses.BLOCKED : AdminStatuses.ACTIVE,
      },
    };
    await dispatch(editAdmin(editAdminRequestData));
    onGetAdmins();
  };

  return (
    <Space size='middle'>
      <Tooltip placement='top' title={record.status === AdminStatuses.BLOCKED ? 'Activate' : 'Block'}>
        <LockOutlined
          onClick={handleEdit}
          style={{ ...iconStyle, ...(isDisabled ? disabledStyle : { color: '#00000073' }) }}
        />
      </Tooltip>
      <Tooltip placement='top' title='Edit'>
        <EditOutlined
          onClick={!isDisabled ? () => onOpenEditModal(record) : undefined}
          style={{ ...iconStyle, ...(isDisabled ? disabledStyle : { color: '#00000073' }) }}
        />
      </Tooltip>
      <Tooltip placement='top' title='Delete'>
        <DeleteOutlined
          onClick={!isDisabled ? () => onOpenDeleteModal(record) : undefined}
          style={{ ...iconStyle, ...(isDisabled ? disabledStyle : { color: '#FF4D4F' }) }}
        />
      </Tooltip>
    </Space>
  );
}
