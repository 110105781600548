import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'antd';

import InputField from '../../../components/InputField/InputField';
import { useAppDispatch } from '../../../store';
import { setUserPassword } from '../../../store/user/asyncThunks/slice';

import styles from './SetNewPasswordForm.module.scss';

type ValuesType = {
  password: string;
  confirmPassword: string;
};

function getParamsFromURL() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const type = urlParams.get('type');
  return { token, type };
}

export default function SetNewPasswordForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { token, type } = getParamsFromURL();

  const onSubmit = async (values: ValuesType) => {
    const requestData = {
      token: token || '',
      newPassword: values.password,
    };
    const res = await dispatch(setUserPassword(requestData));
    if (res?.payload) {
      navigate('/');
    }
  };

  return (
    <Form onFinish={onSubmit} className={styles.form} requiredMark={false}>
      <InputField
        label='New password'
        name='password'
        rules={[
          { required: true, message: 'Please enter new password' },
          { min: 8, message: 'Password must be at least 8 characters long' },
        ]}
        placeholder='input password'
        type='password'
      />
      <InputField
        label='Confirm password'
        name='confirmPassword'
        rules={[
          { required: true, message: 'Confirm new password' },
          { min: 8, message: 'Password must be at least 8 characters long' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Passwords do not match!!'));
            },
          }),
        ]}
        placeholder='input password'
        type='password'
      />
      <div className={styles.formItem}>
        <Button type='primary' htmlType='submit' className={styles.formItem_button}>
          {type === 'set-new-password' ? 'Reset password' : 'Save'}
        </Button>
      </div>
    </Form>
  );
}
