import { MouseEvent, useEffect, useState } from 'react';
import { Button, Form } from 'antd';

import InputField from '../../../components/InputField/InputField';
import { useAppDispatch } from '../../../store';
import { editUser } from '../../../store/user/asyncThunks/slice';
import { IUser } from '../../../types/entities';

import styles from './FormBlock.module.scss';

interface FormValues {
  fullName: string;
  email: string;
}

interface FormBlockProps {
  userData: IUser | null;
  onGetUserData: () => void;
}

export default function FormBlock({ userData, onGetUserData }: FormBlockProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const onSubmit = async (values: FormValues) => {
    const requestBody = {
      email: values.email,
      fullName: values.fullName,
    };
    await dispatch(editUser(requestBody));
    onGetUserData();
  };

  const handleEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsEditing(true);
  };

  useEffect(() => {
    form.setFieldsValue({
      fullName: userData?.fullName,
      email: userData?.email,
    });
  }, [form, userData]);

  return (
    <Form onFinish={onSubmit} className={styles.wrapper} requiredMark={false} form={form}>
      <div className={styles.item}>
        <InputField
          label='Full Name'
          name='fullName'
          rules={[{ required: true, message: 'Please enter your full name' }]}
          placeholder={''}
          disabled={!isEditing}
        />
        <div className={styles.button}>
          {isEditing ? (
            <Button htmlType='submit'>Save</Button>
          ) : (
            <Button htmlType='button' onClick={handleEdit}>
              Edit
            </Button>
          )}
        </div>
      </div>
      <div className={styles.item}>
        <InputField
          label='Email'
          name='email'
          rules={[{ required: true, message: 'Please enter your email' }]}
          placeholder={''}
          disabled
        />
      </div>
    </Form>
  );
}
