import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import { useAppDispatch } from '../../../store';
import { revokeRefreshToken } from '../../../store/user/asyncThunks/slice';
import { logoutAction } from '../../../store/user/slice';

import styles from './PasswordBlock.module.scss';

export default function PasswordBlock() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleResetPassword = () => {
    const refreshToken = localStorage.getItem('refreshToken') || '';
    dispatch(revokeRefreshToken(refreshToken));
    dispatch(logoutAction());
    navigate('/forgot-password');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Password</div>
      <div className={styles.text}>
        Update your password through the button below. You will be redirected to a new page and must follow the
        instructions.
      </div>
      <div className={styles.button}>
        <Button onClick={handleResetPassword}>Reset password</Button>
      </div>
    </div>
  );
}
