export function setTokensToLocalStorage(accessToken: string, refreshToken: string) {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
}

export function removeTokensFromLocalStorage() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
}

export function formatDate(isoString: string): string {
  const date = new Date(isoString);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
    .format(date)
    .replace(',', '');
}

export function getSortOrder(val: string): string {
  return { ascend: 'asc', descend: 'desc' }[val] || '';
}
