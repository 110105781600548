import { Input } from 'antd';

import styles from './CustomSearch.module.scss';

const { Search } = Input;

interface CustomSearchProps {
  onSearch?: (value: string) => void;
  onClear?: () => void;
}

export default function CustomSearch({ onSearch, onClear }: CustomSearchProps) {
  return (
    <div className={styles.search}>
      <Search
        placeholder={'Search...'}
        onSearch={onSearch}
        style={{ width: 264 }}
        onChange={(e) => {
          if (e.target.value === '' && onClear) {
            onClear();
          }
        }}
      />
    </div>
  );
}
