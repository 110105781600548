import { useEffect, useState } from 'react';
import { GetProps, Input } from 'antd';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAppDispatch } from '../../store';
import { getAssets } from '../../store/assets/asyncThunks/slice';

import CryptoAssetsTable from './CryptoAssetsTable/CryptoAssetsTable';
import Toolbar from './Toolbar/Toolbar';

type SearchProps = GetProps<typeof Input.Search>;

export default function CryptoAssetsPage() {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<string>('');

  const { assets, paginationConfig, sort, loading } = useTypedSelector((state) => state.assets);

  const newAssets = assets?.map((asset) => {
    if (asset) {
      return {
        ...asset,
        pnlFeePercent: Math.round((asset.pnlFeePercent ?? 0) * 100 * 1e6) / 1e6,
        flatFeePercent: Math.round((asset.flatFeePercent ?? 0) * 100 * 1e6) / 1e6,
        fundingFeePercent: Math.round((asset.fundingFeePercent ?? 0) * 100 * 1e6) / 1e6,
      };
    }
    return asset;
  });

  const handleGetAssets = async () => {
    const params = {
      filter: searchValue,
      page: paginationConfig.number - 1,
      size: paginationConfig.size,
      sort: sort,
    };
    await dispatch(getAssets(params));
  };

  const handleSearch: SearchProps['onSearch'] = (value, _e) => {
    setSearchValue(value);
  };

  const handleClear = () => {
    setSearchValue('');
  };

  useEffect(() => {
    handleGetAssets();
  }, [sort, paginationConfig.number, paginationConfig.size, searchValue]);

  return (
    <div>
      <Toolbar onSearch={handleSearch} onClear={handleClear} onGetAssets={handleGetAssets} />
      <CryptoAssetsTable
        assets={newAssets}
        loading={loading}
        paginationConfig={paginationConfig}
        onGetAssets={handleGetAssets}
      />
    </div>
  );
}
