import { jwtDecode } from 'jwt-decode';

import { RootState } from '../index';

interface DecodedToken {
  sub: string;
  exp: number;
  role: string;
}

const decodeAccessToken = (accessToken: string | null): DecodedToken | null => {
  if (!accessToken) return null;
  try {
    return jwtDecode<DecodedToken>(accessToken);
  } catch {
    return null;
  }
};

export const selectUserId = (state: RootState): string | null => decodeAccessToken(state.user.accessToken)?.sub || null;

export const selectUserRole = (state: RootState): string | null =>
  decodeAccessToken(state.user.accessToken)?.role || null;
