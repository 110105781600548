import { useEffect } from 'react';
import { Spin } from 'antd';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAppDispatch } from '../../store';
import { getUserData } from '../../store/user/asyncThunks/slice';

import Avatar from './Avatar/Avatar';
import FormBlock from './FormBlock/FormBlock';
import PasswordBlock from './PasswordBlock/PasswordBlock';

import styles from './ProfileDetailsPage.module.scss';

export default function ProfileDetailsPage() {
  const dispatch = useAppDispatch();

  const { loading, userData } = useTypedSelector((state) => state.user);

  const handleGetUserData = async () => {
    dispatch(getUserData());
  };

  useEffect(() => {
    handleGetUserData();
  }, []);

  if (loading) {
    return (
      <div className={styles.loader}>
        <Spin size={'large'} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Profile Details</div>
      <div className={styles.main}>
        <Avatar />
        <FormBlock userData={userData} onGetUserData={handleGetUserData} />
        <PasswordBlock />
      </div>
    </div>
  );
}
