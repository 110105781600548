import { ReactNode } from 'react';
import { Form, FormItemProps, Select, SelectProps, Spin } from 'antd';
import cn from 'classnames';

import styles from './CustomSelect.module.scss';

type OptionType = {
  value: string;
  label: string;
};

interface CustomSelectProps {
  label: string | ReactNode;
  name?: string;
  placeholder: string;
  options: OptionType[] | any;
  loading?: boolean;
  rules?: FormItemProps['rules'];
  showSearch?: boolean;
  value?: string;
  withIcon?: boolean;
  onChange?: (value: string) => void;
  handleSearch?: any;
}

export default function CustomSelect({
  label,
  name,
  placeholder,
  options,
  loading,
  rules,
  showSearch,
  value,
  withIcon,
  onChange,
  handleSearch,
}: CustomSelectProps) {
  const handleChange: SelectProps['onChange'] = (val: string) => {
    onChange?.(val);
  };
  return (
    <Form.Item
      className={cn(styles.item, value && withIcon && styles.item_selected)}
      label={label}
      name={name}
      rules={rules}
    >
      <Select
        options={options}
        placeholder={placeholder}
        showSearch={showSearch}
        onSearch={handleSearch}
        filterOption={false}
        onChange={handleChange}
        value={value || undefined}
        notFoundContent={loading ? <Spin size='small' /> : null}
        allowClear
      />
    </Form.Item>
  );
}
