import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { BASE_URL } from '../constants/constants';

export const requester = axios.create({
  baseURL: BASE_URL,
});

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (token: string) => void;
  reject: (error: any) => void;
}> = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token!);
    }
  });
  failedQueue = [];
};

const setupAxiosInterceptors = (
  onUnauthenticated: () => void,
  onSetTokens: (accessToken: string, refreshToken: string) => void,
  onNavigate: (path: string) => void,
) => {
  const onRequestSuccess = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  };

  const onResponseSuccess = (response: AxiosResponse): AxiosResponse => {
    return response;
  };

  const onResponseError = async (err: AxiosError) => {
    const originalRequest = err.config as InternalAxiosRequestConfig & { _retry?: boolean };
    const status = err.response?.status ?? 0;

    if (status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise<string>((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            if (originalRequest.headers) {
              originalRequest.headers.Authorization = `Bearer ${token}`;
            }
            return requester(originalRequest);
          })
          .catch((error) => Promise.reject(error));
      }

      originalRequest._retry = true;
      isRefreshing = true;
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        onUnauthenticated();
        return Promise.reject(err);
      }
      try {
        const response = await axios.post('/api/token/refresh', { refreshToken });
        onSetTokens(response.data.accessToken, response.data.refreshToken);
        requester.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
        processQueue(null, response.data.accessToken);
        return await requester(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        onUnauthenticated();
        return await Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    if (status === 403) {
      onNavigate('/crypto-assets');
    }

    return Promise.reject(err);
  };

  requester.interceptors.request.use(onRequestSuccess);
  requester.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
