import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, notification } from 'antd';

import InputField from '../../../components/InputField/InputField';
import { useAppDispatch } from '../../../store';
import { resetPassword } from '../../../store/user/asyncThunks/slice';

import styles from './ForgotPasswordForm.module.scss';

type ValuesType = {
  email: string;
};

export default function ForgotPasswordForm() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const onSubmit = async (values: ValuesType) => {
    const email = values.email;
    const res = await dispatch(resetPassword(email));
    if (res?.payload?.nextAt) {
      notification.success({
        message: `Success`,
        description: 'Password reset link has been successfully sent to your email.',
        key: 'password-reset-link-success',
        duration: 3,
      });
      form.resetFields();
    }
  };

  return (
    <Form onFinish={onSubmit} className={styles.form} requiredMark={false} form={form}>
      <InputField
        label='Email'
        name='email'
        rules={[
          { required: true, message: 'Please enter your email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
        placeholder='Enter your email'
      />
      <div className={styles.formButtons}>
        <Link to={'/'} className={styles.formButtons_link}>
          <ArrowLeftOutlined style={{ marginRight: 5, marginTop: 2 }} />
          <span>Back to Login</span>
        </Link>
        <Button type='primary' htmlType='submit' className={styles.formButtons_button}>
          Request reset link
        </Button>
      </div>
    </Form>
  );
}
