import { Navigate, useLocation } from 'react-router-dom';

import { removeTokensFromLocalStorage } from '../helpers/helpers';
import { useTypedSelector } from '../hooks/useTypedSelector';

const PublicRoutes = ({ children }) => {
  const location = useLocation();

  const { isAuth } = useTypedSelector((state) => state.user);

  if (!isAuth) {
    removeTokensFromLocalStorage();
    return children;
  }

  return <Navigate to={location.state?.from || '/admin-management'} replace />;
};

export default PublicRoutes;
