import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { requester } from '../../../api/axiosInstance';
import { GetAssetsResponse, ICreateAsset, IEditAsset } from '../types';

export const createAsset = createAsyncThunk<any, ICreateAsset, { rejectValue: any }>(
  '/assets/createAsset',
  async (requestBody, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await requester.post('/api/assets', requestBody);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { message: 'Error creating asset' });
    }
  },
);

export const getAssets = createAsyncThunk<GetAssetsResponse, Record<string, any>, { rejectValue: any }>(
  'assets/getAssets',
  async (params, { rejectWithValue }) => {
    try {
      const response = await requester.get('/api/assets', { params });
      return { data: response.data };
    } catch (error: any) {
      console.log('error', error);
      return rejectWithValue(error.response?.data || { message: 'Failed to fetch assets' });
    }
  },
);

export const deleteAsset = createAsyncThunk('/assets/deleteAsset', async (assetId: number) => {
  return requester.delete(`/api/assets/${assetId}`);
});

export const editAsset = createAsyncThunk<any, IEditAsset, { rejectValue: any }>(
  '/api/assets/editAsset',
  async (editData, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await requester.put(`/api/assets/${editData.assetId}`, editData.body);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { message: 'Error editing asset' });
    }
  },
);

export const getAssetsMarketSymbolsCoinGecko = createAsyncThunk<any, any, { rejectValue: string }>(
  'assets/getAssetsMarketSymbolsCoinGecko',
  async (params, { rejectWithValue }) => {
    try {
      const response = await requester.get('/api/assets/market-symbols', { params });
      return { data: response.data.content };
    } catch (error) {
      return rejectWithValue('Failed to fetch assets');
    }
  },
);

export const getAssetsMarketSymbolsFinage = createAsyncThunk<any, any, { rejectValue: string }>(
  'assets/getAssetsMarketSymbolsFinage',
  async (params, { rejectWithValue }) => {
    try {
      const response = await requester.get('/api/assets/market-symbols', { params });
      return { data: response.data.content };
    } catch (error) {
      return rejectWithValue('Failed to fetch assets');
    }
  },
);

export const uploadAssetImage = createAsyncThunk(
  '/assets/uploadAssetImage',
  async ({ file, assetId }: { file: File; assetId: string | number }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await requester.post(`/api/assets/${assetId}/icon`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Upload failed');
    }
  },
);
