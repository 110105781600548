import { useEffect } from 'react';
import { Button, Form, Modal } from 'antd';

import InputField from '../../../../components/InputField/InputField';
import { useAppDispatch } from '../../../../store';
import { editAdmin } from '../../../../store/admins/asyncThunks/slice';
import { DataType } from '../types';

import RoleStatusBlock from './RoleStatusBlock/RoleStatusBlock';

import styles from './EditAdminModal.module.scss';

const modalStyles = {
  content: {
    padding: 0,
  },
  header: {
    padding: '16px 24px',
    borderBottom: '1px solid #F0F0F0',
    marginBottom: 0,
  },
};

interface EditAdminModalProps {
  isOpenModal: boolean;
  editAdminData: DataType | null;
  onCloseEditModal: () => void;
  onGetAdmins: () => void;
}

interface ValuesData {
  fullName: string;
  email: string;
  role: string;
  status: string;
}

export default function EditAdminModal({
  isOpenModal,
  editAdminData,
  onCloseEditModal,
  onGetAdmins,
}: EditAdminModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const onSubmit = async (values: ValuesData) => {
    const editAdminRequestData = {
      userId: editAdminData?.id || null,
      body: {
        fullName: values?.fullName || '',
        email: values?.email || '',
        role: values?.role || '',
        status: values?.status || '',
      },
    };
    dispatch(editAdmin(editAdminRequestData));
    onGetAdmins();
    onCloseEditModal();
  };

  useEffect(() => {
    form.setFieldsValue({
      fullName: editAdminData?.fullName,
      email: editAdminData?.email,
      role: editAdminData?.role,
      status: editAdminData?.status,
    });
  }, [form, editAdminData]);

  return (
    <Modal title='Edit admin' open={isOpenModal} onCancel={onCloseEditModal} footer={null} styles={modalStyles}>
      <Form onFinish={onSubmit} className={styles.form} requiredMark={false} form={form}>
        <div className={styles.main}>
          <InputField
            label='Full name'
            name='fullName'
            rules={[{ required: true, message: 'Please enter your full name' }]}
            placeholder='Enter full name'
          />
          <InputField
            label='Email'
            name='email'
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
            placeholder='Enter user’s email'
          />
          <RoleStatusBlock />
        </div>
        <div className={styles.footer}>
          <Button key='cancel' onClick={onCloseEditModal} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button key='ok' type='primary' htmlType='submit' className={styles.okButton}>
            Edit
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
