import { useEffect } from 'react';
import { Form, Radio } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

import { AdminStatuses } from '../../../../../../constants/constants';

import styles from './Status.module.scss';

export default function Status() {
  const form = useFormInstance();
  const value = form.getFieldValue('status');

  useEffect(() => {
    if (value === undefined) {
      form.setFieldsValue({ status: AdminStatuses.ACTIVE });
    }
  }, [value, form]);

  return (
    <div>
      <div className={styles.title}>Status</div>
      <Form.Item name='status'>
        <Radio.Group value={value}>
          <Radio value={AdminStatuses.ACTIVE} className={styles.customRadio}>
            Active
          </Radio>
          <Radio value={AdminStatuses.BLOCKED} className={styles.customRadio}>
            Blocked
          </Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
}
