import { createAsyncThunk } from '@reduxjs/toolkit';

import { requester } from '../../../api/axiosInstance';
import { GetAdminsResponse, ICreateAdminRequest, IEditAdminRequest } from '../types';

export const getAdmins = createAsyncThunk<GetAdminsResponse, Record<string, any>, { rejectValue: string }>(
  'admins/getAdmins',
  async (params, { rejectWithValue }) => {
    try {
      const response = await requester.get('/api/admins', { params });
      return { data: response.data };
    } catch (error) {
      return rejectWithValue('Failed to fetch admins');
    }
  },
);

export const createAdmin = createAsyncThunk<any, ICreateAdminRequest, { rejectValue: any }>(
  '/admins/createAdmin',
  async (requestBody, { rejectWithValue }) => {
    try {
      const response = await requester.post('/api/admins', requestBody);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { message: 'Error creating administrator' });
    }
  },
);

export const deleteAdmin = createAsyncThunk('/admins/deleteAdmin', async (userId: number) => {
  return requester.delete(`/api/admins/${userId}`);
});

export const editAdmin = createAsyncThunk('/admins/editAdmin', async (editAdminRequestData: IEditAdminRequest) => {
  return requester.patch(`/api/admins/${editAdminRequestData.userId}`, editAdminRequestData.body);
});
