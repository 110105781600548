export enum AdminRolesText {
  SUPER_ADMIN = 'Super Admin',
  ADMIN = 'Admin',
}

export enum AdminRoles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
}

export enum AdminStatuses {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
}

// Todo: During local development, we retrieve the API through a proxy.
export const BASE_URL = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_API_URL;
