import { ReactNode } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form, FormItemProps, Input, InputNumber } from 'antd';

import styles from './InputField.module.scss';

interface InputFieldProps {
  label: string | ReactNode;
  name: string;
  rules: FormItemProps['rules'];
  placeholder: string;
  type?: string;
  disabled?: boolean;
}

export default function InputField({
  label,
  name,
  rules,
  placeholder,
  type = 'text',
  disabled = false,
}: InputFieldProps) {
  return (
    <Form.Item className={styles.item} label={label} name={name} rules={rules}>
      {type === 'password' && (
        <Input.Password
          placeholder={placeholder}
          iconRender={(visible) =>
            visible ? (
              <EyeTwoTone style={{ fontSize: '18px' }} />
            ) : (
              <EyeInvisibleOutlined style={{ fontSize: '18px' }} />
            )
          }
          disabled={disabled}
        />
      )}
      {type === 'number' && (
        <InputNumber
          type='number'
          placeholder={placeholder}
          disabled={disabled}
          onKeyDown={(e) => {
            if (['e', 'E', '+', '-'].includes(e.key)) {
              e.preventDefault();
            }
          }}
        />
      )}
      {type === 'text' && <Input placeholder={placeholder} disabled={disabled} />}
    </Form.Item>
  );
}
